import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateChildren } from "../../Redux/UserData/action";
import AddIcon from "@mui/icons-material/Add";

// Custom hook for detecting screen size
const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(window.matchMedia(query).matches);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);
    media.addEventListener("change", listener);
    return () => media.removeEventListener("change", listener);
  }, [matches, query]);

  return matches;
};

const ChildrenCount = ({ roomsCount, selectedValue, handleChildrenChange }) => {
  const isMobile = useMediaQuery("(max-width: 768px)"); // Adjust breakpoint as needed
  const maxChildren = roomsCount * 5 - selectedValue;
  const [selectedChildValue, setSelectedChildValue] = useState(0);

  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    const value = +e.target.value;
    setSelectedChildValue(value);
    dispatch(updateChildren(value));
    handleChildrenChange(value);
  };

  const incrementChildCount = () => {
    if (selectedChildValue < maxChildren) {
      const newCount = selectedChildValue + 1;
      setSelectedChildValue(newCount);
      dispatch(updateChildren(newCount));
      handleChildrenChange(newCount);
    } else {
      showAlert();
    }
  };

  const decrementChildCount = () => {
    if (selectedChildValue > 0) {
      const newCount = selectedChildValue - 1;
      setSelectedChildValue(newCount);
      dispatch(updateChildren(newCount));
      handleChildrenChange(newCount);
    }
  };

  const showAlert = () => {
    alert(
      `Number of guests exceeds the maximum allowed. \nMaximum guests allowed: ${
        roomsCount * 3
      }\nPlease update your selection.`
    );
  };

  useEffect(() => {
    setSelectedChildValue(0);
    dispatch(updateChildren(0));
    handleChildrenChange(0);
  }, [selectedValue, roomsCount, dispatch]);

  return (
    <div className="count-main-cont">
      <p className="select-p-mobile-view">Children</p>
      {isMobile ? (
        <select
          className="mobile-select-mob-view"
          value={selectedChildValue}
          onChange={handleInputChange}
          style={{
            border: "1px solid transparent",
            borderRadius: "4px",
          }}
        >
          {[...Array(maxChildren + 1)].map((_, i) => (
            <option key={i} value={i}>
              {i}
            </option>
          ))}
        </select>
      ) : (
        <div className="desktop-room-counter">
          <button
            onClick={decrementChildCount}
            disabled={selectedChildValue <= 0}
            // style={{
            //   padding: "10px",
            //   borderRadius: "4px",
            //   marginRight: "5px",
            // }}
          >
            {/* - */}➖
          </button>
          <span className="span-count-all">{selectedChildValue}</span>
          <button
            onClick={incrementChildCount}
            disabled={selectedChildValue >= maxChildren}
            // style={{
            //   padding: "10px",
            //   borderRadius: "4px",
            //   marginLeft: "5px",
            // }}
          >
            ➕{/* <AddIcon /> */}
          </button>
        </div>
      )}
    </div>
  );
};

export default ChildrenCount;
